/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: '8bitOperatorPlus-Bold';
  src: url('/assets/fonts/8bitOperatorPlus-Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/8bitOperatorPlus-Bold.woff') format('woff'), url('/assets/fonts/8bitOperatorPlus-Bold.ttf')  format('truetype'), url('/assets/fonts/8bitOperatorPlus-Bold.svg#8bitOperatorPlus-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '8bitOperatorPlus-Regular';
  src: url('/assets/fonts/8bitOperatorPlus-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/8bitOperatorPlus-Regular.woff') format('woff'), url('/assets/fonts/8bitOperatorPlus-Regular.ttf')  format('truetype'), url('/assets/fonts/8bitOperatorPlus-Regular.svg#8bitOperatorPlus-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JF-Dot-Shinonome-14-Regular';
  src: url('/assets/fonts/JF-Dot-Shinonome-14-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/JF-Dot-Shinonome-14-Regular.woff') format('woff'), url('/assets/fonts/JF-Dot-Shinonome-14-Regular.ttf')  format('truetype'), url('/assets/fonts/JF-Dot-Shinonome-14-Regular.svg#JF-Dot-Shinonome-14-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  position: relative;
}

body {
  background: black;
  color: white;
  padding: 0;
  margin: 0;
  font-family: 'JF-Dot-Shinonome-14-Regular', monospace;
}

.lazyload, .lazyloading, .lazyloaded {
  transition: opacity 0.1s ease-in;
}

.lazyload, .lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
}

.yellow {
  color: #F9FF10;
}

.font-8bit-bold {
  font-family: '8bitOperatorPlus-Bold', monospace;
}

.font-8bit-regular {
  font-family: '8bitOperatorPlus-Regular', monospace;
}

.font-8bit-japanese {
  font-family: 'JF-Dot-Shinonome-14-Regular', monospace;
}

.rtl {
  direction: rtl;
}

.pixel {
  image-rendering: pixelated;
}

.smallcaps {
  font-variant: small-caps;
}

.pixel-shadow {
  box-shadow: 4px 4px 0 0 #666666;
}

#center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.text-shadow {
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.4);
}

.price-ribbon {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% calc(100% - 15px), 0% 100%, 0% 0%);
}

.textbubble {
  position: relative;
  padding: 1.25rem 1rem;
  line-height: 1.25rem;
  border: 1px solid white;
  border-radius: 25px;
}

.textbubble.left:before {
  content: "";
  position: absolute;
  bottom: 1.5rem;
  left: -9px;
  background: black;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  border-left: 1px solid white;
  border-bottom: 1px solid white;
}

.textbubble.right:before {
  content: "";
  position: absolute;
  bottom: 1.5rem;
  right: -9px;
  background: black;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  border-top: 1px solid white;
  border-right: 1px solid white;
}

@keyframes note {
  from {
    opacity: 1;
    offset-distance: 0%;
    offset-rotate: 0deg;
  }
  to {
    opacity: 0;
    offset-distance: 100%;
    offset-rotate: 0deg;
  }
}
